@primary: #32273B;
@secondary: #DDDDDD;
@highlight: #CC0000;

/** GLOBAL **/
input, select, textarea {
  font-size: 1rem !important;
}
a:not(.btn) {
  position: relative;
  opacity: 1;
}
a:not(.btn):hover {
  opacity: 0.56 !important;
  transition: all 0.25s !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

.code-block {
  padding: 0;
}

.center {
  text-align: center;
  margin: 0 auto;
}

#collection-635f666d52d90a16d7dde61c .header-announcement-bar-wrapper, /** Spindle Repair Process **/
#collection-6188c77f9ee172551e11cb3f .header-announcement-bar-wrapper, /** 404 **/
#collection-63635aec4c7fb5355853e01d .header-announcement-bar-wrapper, /** Tapered **/
#collection-6130147c438f0945b3700487 .header-announcement-bar-wrapper, /** Contact Us **/
#collection-613012714e477b5770fb418d .header-announcement-bar-wrapper, /** Spindle Repair **/
#collection-613019bae371f51639557232 .header-announcement-bar-wrapper /** About Us **/ {
  background: linear-gradient(@secondary, transparent);

  .box.is-dragover {
    background-color: #f8f8f8;
  }
  .header-nav-folder-item {
    background: hsl(0deg 0% 95% / 30%) !important;
    &:hover {
      background: hsl(0deg 0% 95%) !important;
    }
  }
}

#collection-635f6cf7a6daf02140b33e7a {
  .code-block {
    .sqs-block-content {
      height: 100%;
    }
  }
}

.step {
  h2 {
    display: flex;
    gap: 36px;
    font-size: 32px;
    align-items: center;
    margin: 0;
    font-family: 'Clarkson', Arial, Helvetica, sans-serif;
    &::before {
      content: attr(data-step);
      text-align: right;
      font-family: 'monotype-grotesque-extended', Arial, Helvetica, sans-serif;
      border-radius: 50%;
      background: radial-gradient(circle, #ffffff 50%, #cc0000 52%, #cc0000 67%, #ffffff 68%);
      color: #c00 !important;
      font-size: 67px;
      margin: 0;
      flex: 0 0 71px;
      box-sizing: border-box;
      padding: 3px 8px 0 0;
    }
  }
}

.rating {
  font-size: 4.0vw;
  color: @highlight;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/** Home page **/
#collection-61301462791f5a4490ca10e6 {
  .header-announcement-bar-wrapper {
    height: 15vh;
    background: #ccccccad;
  }

  section[data-section-id="636352c73033097a0ccd2604"],
  section[data-section-id="63635312076a893f26a8a8a7"],
  section[data-section-id="6191eb5820f31c383043f7de"],
  section[data-section-id="6192047bc4e7fe54ea2ef64f"],
  section[data-section-id="6361f929f557622e08bb70be"],
  section[data-section-id="63f63614d75b1ce57b6db45a"] {
    & .section-border {
      background-color: transparent;
    }
    & .section-background {
      background-image: 	url('https://centerline-inc.s3.us-west-1.amazonaws.com/dist/static/bo-play.png') !important;
      background-color: #464646 !important;
      background-attachment: fixed;
      clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 52% 90%, 50% 100%, 48% 90%, 0% 90%);
    }
  }
  section[data-section-id="6192047bc4e7fe54ea2ef64f"] {
    background-color: white !important;
  }

  /** info-banner **/
  section[data-section-id="636353aa7f60247e2d7649b1"] .section-background {
    background-color: black !important;
  }
}
/** End Home page **/

/** Banner **/
.banner {
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 26px;
  top: -47px;
  width: 100%;
  height: 47px;
  color: white;
  background: hsl(40, 10%, 20%);
  z-index: 99999;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.banner.show {
  opacity: 1;
  top: 0px;
}
.contact {
  font-size: 12px;
}
.social {
  margin-left: auto;
  padding-right: 5%;
}
.social .sqs-svg-icon--wrapper {
  width: 30px;
  height: 30px;
}
.social .sqs-use--icon {
  fill: white;
}

.banner .contact a {
  text-decoration: underline;
  margin-right: 20px;
}
.banner [class^="icon-"]:before{
  color: #c00;
}
.info-banner {
  font-family: halyard-text, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-transform: uppercase;
  font-size: 14px;
    @media (min-width: 670px){
    &span:nth-of-type(-n+3)::after { /** Select first 3 **/
      content: '•';
      position: absolute;
      margin-left: 5vw;
      font-size: 17px;
      line-height: 17px;
    }
  }
}

.md-list {
  font-size: calc((1.1 - 1) * .2vw + 1rem);
}

/** End Banner **/

/** Slideshow **/
@slideshowHeight: 76vh;
.user-items-list-banner-slideshow  {
  &[data-card-horizontal-position="left"] .slide {
    height: @slideshowHeight;
  }
  .slideshow-wrapper {
    height: @slideshowHeight;
  }
  .slides--initialized {
    height: @slideshowHeight !important;

    li:nth-of-type(1) {
      padding: 9% 22% 0% 8% !important;
      .list-item-card-background {
        width: auto !important;
      }
    }
    li:nth-of-type(2){
      padding: 10% 0 0 10% !important;
    }
    li:nth-of-type(3){
      padding: 9% 24% 0% 7% !important;
      .list-item-card-background {
        width: auto !important;
      }
    }

    .list-item-content__description, .list-item-content__title {
      text-shadow: 4px 5px 4px #332e2e;
    }

    .list-item-content__button-container {
      a.list-item-content__button {
        padding: 1em 1.5em;
        margin-top: 58px;
      }
    }
  }
}


/** End Slideshow **/

/** Nav Links **/
.header-nav-item a {
  position: relative;
}
.header-nav-item:not(.header-nav-item--folder) a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
  transition: all 0.25s;
}
.header-nav-item.header-nav-item--folder.header-nav-item--active > a,
:not(.header--menu-open) .header-nav-wrapper .header-nav-item--active > a {
  border-bottom: 1px solid #cc0000;
  background-image: none;
}
body:not(.header--menu-open) .header-nav-folder-item--active {
  .header-nav-folder-item-content {
    background-image: none;
  }
  > a {
    &:hover {
      opacity: 1 !important;
    }
  }
}

@headerHeight: 15vh;
body.tweak-transparent-header .header:not(.shrink):not(.transparent-header-theme--override){
  height: @headerHeight;
}
.header-announcement-bar-wrapper {
  padding: 0 3vw !important;
  height: @headerHeight;
}
.header-nav-item:not(.header-nav-item--folder) a:hover:after {
  width: 100%;
}

.header-nav-list > div {
  white-space: normal !important;
}
.header .header-layout-nav-right .header-nav .header-nav-item--folder .header-nav-folder-content {
  left: 0 !important;
  top: 91% !important;
  border-top: 2px solid #c00;
  padding: 0 !important;
  background-color: white;
  text-align: left;
}

.header-nav-folder-item {
  background: white;
  padding: 8px;
  border-bottom: 1px solid rgba(0,0,0,.1);

  &:hover {
    background: hsl(0, 0, 90%);
  }
}

.header-nav-folder-item a {
   color: black !important;
}

/** End Navigation Dropdown **/

/** Select Input **/
select {
  width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.field.select::after {
  content: '\f107';
  font-family: 'fontello';
  position: absolute;
  bottom: 17%;
  font-size: 28px;
  right: 2%;
}

/** End Select Input **/

/** Mobile Classes **/
.center-right {
  text-align: center;
}
@media screen and (min-width: 600px) {
  .center-right {
    text-align: right;
  }
}

/** End Mobile Classes **/
/** Precision Machining and Grinding Page **/
body#collection-613012714e477b5770fb4195 .header-announcement-bar-wrapper {
    background-color: rgba(221, 221, 221, .6);
}

/** End Precision Machining and Grinding Page **/

/** Spindle Repair Page **/

#page-section-61301a4355740c1cca48494d .code-block img {
  max-width: 700px;
  padding: 20px;
  box-sizing: border-box;
}

/** End Spindle Repair Page **/

/** ---------------- CUSTOM FORM CSS----------------- **/

.error-box {
  height: 0;
  overflow: hidden;
  margin-bottom: 10px;
  transition: all .5s;
  &.required {
    height: 46px;
  }

  p {
    background: #cc0100;
    color: white;
  }
}

/** File Upload **/
			.box
				{
					font-size: 1.25rem; /* 20 */
					background-color: transparent;
					position: relative;
					padding: 25px;
          min-height: 200px;
          margin: 6px 0 4px;
          margin-bottom: 27px;
				}
				.box.has-advanced-upload
				{
					outline: 2px dashed #bbb;
					outline-offset: -10px;

					-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
					transition: outline-offset .15s ease-in-out, background-color .15s linear;
				}
				.box.is-dragover
				{
					outline-offset: -20px;
					outline-color: #c8c8c8;
					background-color: #fff;
				}
					.box__dragndrop,
					.box__icon
					{
						display: none;
					}
					.box.has-advanced-upload .box__dragndrop
					{
						display: inline;
					}
					.box.has-advanced-upload .box__icon
					{
						width: 100%;
						height: 80px;
						fill: #92b0b3;
						display: block;
						margin-bottom: 40px;
					}

					.box.is-uploading .box__input,
					.box.is-success .box__input,
					.box.is-error .box__input
					{
						visibility: hidden;
					}

					.box__uploading,
					.box__success,
					.box__error
					{
						display: none;
					}
					.box.is-uploading .box__uploading,
					.box.is-success .box__success,
					.box.is-error .box__error
					{
						display: block;
						position: absolute;
						top: 50%;
						right: 0;
						left: 0;

						-webkit-transform: translateY( -50% );
						transform: translateY( -50% );
					}
					.box__uploading
					{
						font-style: italic;
					}
					.box__success
					{
						-webkit-animation: appear-from-inside .25s ease-in-out;
						animation: appear-from-inside .25s ease-in-out;
					}
						@-webkit-keyframes appear-from-inside
						{
							from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
							75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
							to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
						}
						@keyframes appear-from-inside
						{
							from	{ transform: translateY( -50% ) scale( 0 ); }
							75%		{ transform: translateY( -50% ) scale( 1.1 ); }
							to		{ transform: translateY( -50% ) scale( 1 ); }
						}

					.box__restart
					{
						font-weight: 700;
					}
					.box__restart:focus,
					.box__restart:hover
					{
						color: #39bfd3;
					}

					.js .box__file
					{
						width: 0.1px;
						height: 0.1px;
						opacity: 0;
						overflow: hidden;
						position: absolute;
						z-index: -1;
					}
					.js .box__file + label
					{
						max-width: 80%;
						text-overflow: ellipsis;
						white-space: nowrap;
						cursor: pointer;
						display: inline-block;
						overflow: hidden;
					}
					.js .box__file + label:hover strong,
					.box__file:focus + label strong,
					.box__file.has-focus + label strong
					{
						color: #39bfd3;
					}
					.js .box__file:focus + label,
					.js .box__file.has-focus + label
					{
						outline: 1px dotted #000;
						outline: -webkit-focus-ring-color auto 5px;
					}
						.js .box__file + label *
						{
							/* pointer-events: none; */ /* in case of FastClick lib use */
						}

					.no-js .box__file + label
					{
						display: none;
					}

					.no-js .box__button
					{
						display: block;
					}
					.box__button
					{
						font-weight: 700;
						color: #e5edf1;
						background-color: #39bfd3;
						display: none;
						padding: 8px 16px;
						margin: 40px auto 0;
					}
          .box__button:hover,
          .box__button:focus
          {
            background-color: #0f3c4b;
          }

          .file__label {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            min-height: 198px;
            justify-content: center;
            cursor: pointer;

            span {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: inline-block;
              width: 110px !important;
            }
          }
          .thumbnail {
            padding: 10px;
            font-size: 13px;

            img {
              width: 80px !important;
              height: 66px;
              border: 1px solid black;
              padding: 10px;
              border-radius: 10px;
              display: block;
              margin-top: 10px !important;
            }
          }

/** End File Upload **/

/** Intl-Tel-Input **/
.iti__flag {background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/img/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/img/flags@2x.png");}
}

#phone {
  padding-left: 49px;
}

/** ---------------- END CUSTOM FORM CSS ------------------- **/

/** ---------------- BANNER CSS ---------------------------- **/
[data-section-id="635e082e2c677e36ee958858"] {
  padding-top: 0 !important;
}
.user-items-list-banner-slideshow .user-items-list-banner-slideshow__arrow-icon {
  width: 1em;
  height: 1em;
}
.user-items-list-banner-slideshow .user-items-list-banner-slideshow__arrow-button {
  width: 30px;
  height: 30px;
}
/** FONTS **/

@font-face {
  font-family: 'fontello';
  src: url('https://centerline-inc.s3.us-west-1.amazonaws.com/font/fontello.eot') format('embedded-opentype'),
    url('https://centerline-inc.s3.us-west-1.amazonaws.com/font/fontello.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-star:before {
  content: '\e800';
}

/* '' */
.icon-star-empty:before {
  content: '\e801';
}

/* '' */
.icon-thumbs-up:before {
  content: '\e802';
}

/* '' */
.icon-thumbs-down:before {
  content: '\e803';
}

/* '' */
.icon-target:before {
  content: '\e804';
}

/* '' */
.icon-target-1:before {
  content: '\e805';
}

/* '' */
.icon-target-2:before {
  content: '\e806';
}

/* '' */
.icon-tag:before {
  content: '\e807';
}

/* '' */
.icon-tags:before {
  content: '\e808';
}

/* '' */
.icon-flag:before {
  content: '\e809';
}

/* '' */
.icon-users:before {
  content: '\e80a';
}

/* '' */
.icon-help-circled:before {
  content: '\e80b';
}

/* '' */
.icon-comment:before {
  content: '\e80c';
}

/* '' */
.icon-book:before {
  content: '\e80d';
}

/* '' */
.icon-magnet:before {
  content: '\e80e';
}

/* '' */
.icon-credit-card:before {
  content: '\e80f';
}

/* '' */
.icon-wrench:before {
  content: '\e810';
}

/* '' */
.icon-cog-alt:before {
  content: '\e811';
}

/* '' */
.icon-cog:before {
  content: '\e812';
}

/* '' */
.icon-search:before {
  content: '\e813';
}

/* '' */
.icon-truck:before {
  content: '\e814';
}

/* '' */
.icon-mail:before {
  content: '\e815';
}

/* '' */
.icon-ok:before {
  content: '\e816';
}

/* '' */
.icon-thumbs-up-1:before {
  content: '\e817';
}

/* '' */
.icon-thumbs-down-1:before {
  content: '\e818';
}

/* '' */
.icon-phone:before {
  content: '\e819';
}

/* '' */
.icon-magnet-1:before {
  content: '\e81a';
}

/* '' */
.icon-credit-card-1:before {
  content: '\e81b';
}

/* '' */
.icon-wrench-1:before {
  content: '\e81c';
}

/* '' */
.icon-cog-alt-1:before {
  content: '\e81d';
}

/* '' */
.icon-cog-1:before {
  content: '\e81e';
}

/* '' */
.icon-angle-left-1:before {
  content: '\e81f';
}

/* '' */
.icon-angle-right-1:before {
  content: '\e820';
}

/* '' */
.icon-angle-up-1:before {
  content: '\e821';
}

/* '' */
.icon-angle-down-1:before {
  content: '\e822';
}

/* '' */
.icon-rss:before {
  content: '\f09e';
}

/* '' */
.icon-certificate:before {
  content: '\f0a3';
}

/* '' */
.icon-beaker:before {
  content: '\f0c3';
}

/* '' */
.icon-docs:before {
  content: '\f0c5';
}

/* '' */
.icon-mail-alt:before {
  content: '\f0e0';
}

/* '' */
.icon-comment-empty:before {
  content: '\f0e5';
}

/* '' */
.icon-doc-text:before {
  content: '\f0f6';
}

/* '' */
.icon-angle-left:before {
  content: '\f104';
}

/* '' */
.icon-angle-right:before {
  content: '\f105';
}

/* '' */
.icon-angle-up:before {
  content: '\f106';
}

/* '' */
.icon-angle-down:before {
  content: '\f107';
}

/* '' */
.icon-flag-checkered:before {
  content: '\f11e';
}

/* '' */
.icon-help:before {
  content: '\f128';
}

/* '' */
.icon-rss-squared:before {
  content: '\f143';
}

/* '' */
.icon-doc-text-inv:before {
  content: '\f15c';
}

/* '' */
.icon-down:before {
  content: '\f175';
}

/* '' */
.icon-up:before {
  content: '\f176';
}

/* '' */
.icon-left:before {
  content: '\f177';
}

/* '' */
.icon-right:before {
  content: '\f178';
}

/* '' */
.icon-cube:before {
  content: '\f1b2';
}

/* '' */
.icon-cubes:before {
  content: '\f1b3';
}

/* '' */
